* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: 'Inter', sans-serif;
  font-size: 16px;
  color: #FFF;

}
body::-webkit-scrollbar {
  display: none;
}